import { UserRoles } from "@/enums/UserRoles";

export const partsAllowedAdminRoles = [
  UserRoles.superAdmin,
  UserRoles.purchaseTeamAdmin,
  UserRoles.customerServiceAdmin,
  UserRoles.engineeringTeam,
  UserRoles.partsViewer,
  UserRoles.partsEditor,
];
