import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";

import store from "@/store";
import { RouteName } from "@/enums/RouteName";
import { UserRoles } from "@/enums/UserRoles";
import { trackOrderAllowedRoles } from "@/constants/roles/trackOrderAllowedRoles";
import { rfqAllowedRoles } from "@/constants/roles/rfqAllowedRoles";
import { defaultPageName } from "@/composables/routing/defaultPageName";
import { customersAllowedAdminRoles } from "@/constants/roles/customersAllowedAdminRoles";
import { checkoutAllowedRoles } from "@/constants/roles/checkoutAllowedRoles";
import { costingAllowedRoles } from "@/constants/roles/costingAllowedRoles";
import { partDetailsAllowedRoles } from "@/constants/roles/partDetailsAllowedRoles";
import { usersAllowedRoles } from "@/constants/roles/usersAllowedRoles";
import { partInfoAllowedRoles } from "@/constants/roles/partInfoAllowedRoles";
import { partDetailsDocumentsAllowedRoles } from "@/constants/roles/partDetailsDocumentsAllowedRoles";
import { uploadDataAllowedRoles } from "@/constants/roles/uploadDataAllowedRoles";
import { quoteAllowedRoles } from "@/constants/roles/quoteAllowedRoles";
import { technicalFeedbackAllowedRoles } from "@/constants/roles/technicalFeedbackAllowedRoles";
import { trackOrderTechnicalFeedbackAllowedRoles } from "@/constants/roles/trackOrderTechnicalFeedbackAllowedRoles";
import { costingParametersAllowedRoles } from "@/constants/roles/costingParametersAllowedRoles";
import { partDetailsCostingAllowedRoles } from "@/constants/roles/partDetailsCostingAllowedRoles";
import { partsAllowedAdminRoles } from "@/constants/roles/partsAllowedAdminRoles";
import { openBidsPageAllowedRoles } from "@/constants/roles/openBidsPageAllowedRoles";
import { MessageReactive, MessageRenderMessage, NAlert } from "naive-ui";
import { h } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "keep-alive-layout" */ "@/layouts/KeepAliveLayout.vue"
      ),
    children: [
      {
        path: "/quotes",
        component: () =>
          import(
            /* webpackChunkName: "layout-quotes" */ "@/views/quotes/index.vue"
          ),
        children: [
          {
            path: "",
            name: RouteName.Quotes,
            component: () =>
              import(
                /* webpackChunkName: "quote-list" */ "@/views/quotes/QuoteList.vue"
              ),
            meta: {
              title: "Quotes",
              roles: quoteAllowedRoles,
            },
          },
          {
            path: "costing/:rfqPartId",
            name: RouteName.Costing,
            component: () =>
              import(
                /* webpackChunkName: "quote-costing" */ "@/views/Costing.vue"
              ),
            meta: {
              title: "Costing",
              roles: costingAllowedRoles,
            },
          },
          {
            path: "summary/:rfqPartId",
            name: RouteName.Summary,
            component: () =>
              import(/* webpackChunkName: "summary" */ "@/views/Summary.vue"),
            meta: {
              title: "Summary",
              roles: costingAllowedRoles,
            },
          },
          {
            path: "technical-feedback/:rfqPartId",
            name: RouteName.TechnicalFeedback,
            component: () =>
              import(
                /* webpackChunkName: 'technical-feedback' */ "@/views/PartTechnicalFeedback.vue"
              ),
            meta: {
              title: "Technical Feedback",
              roles: costingAllowedRoles,
            },
          },
          {
            path: "reject/:rfqPartId",
            name: RouteName.Reject,
            component: () =>
              import(/* webpackChunkName: 'reject' */ "@/views/Reject.vue"),
            meta: {
              title: "Reject",
            },
          },
          {
            path: "ask-authorization/:rfqPartId",
            name: RouteName.AskAuthorization,
            component: () =>
              import(
                /* webpackChunkName: 'ask-authorization' */ "@/views/AskAuthorization.vue"
              ),
            meta: {
              title: "Ask Authorization",
            },
          },
          {
            path: ":id",
            name: RouteName.QuoteDetails,
            component: () =>
              import(
                /* webpackChunkName: 'quote-details' */ "@/views/quotes/QuoteDetails.vue"
              ),
            meta: {
              title: "Quote",
            },
          },
          {
            path: "checkout/:id",
            name: RouteName.Checkout,
            component: () =>
              import(/* webpackChunkName: 'checkout' */ "@/views/Checkout.vue"),
            meta: {
              title: "Checkout",
              roles: checkoutAllowedRoles,
            },
          },
          {
            path: "checkout/:id/purchase-order",
            name: RouteName.CheckOutWithPurchaseOrder,
            component: () =>
              import(
                /* webpackChunkName: 'check-out-with-purchase-order' */ "@/views/CheckOutWithPurchaseOrder.vue"
              ),
            meta: {
              title: "Check out with PO",
              roles: checkoutAllowedRoles,
            },
          },
          {
            path: "request-for-quote/:id?/:customerId?/:userId?",
            name: RouteName.RequestForQuote,
            component: () =>
              import(
                /* webpackChunkName: "rfq" */ "@/views/RequestForQuote.vue"
              ),
            meta: {
              title: "Quote request",
              roles: rfqAllowedRoles,
            },
          },
          {
            path: ":quoteId/:rfqPartId/:partId?",
            redirect: (to) => {
              return `${to.params.quoteId}/${to.params.partId}/technical-feedback${to.params.query}`;
            },
            name: RouteName.PartDetails,
            component: () =>
              import(
                /* webpackChunkName: 'part-details' */ "@/views/PartDetails.vue"
              ),
            meta: {
              title: "Part Details",
              roles: partDetailsAllowedRoles,
            },
            children: [
              {
                path: "part-info",
                name: RouteName.PartDetailsPartInfo,
                component: () =>
                  import(
                    /* webpackChunkName: 'part-details-part-info' */ "@/components/part-details/PartDetailsPartInfo.vue"
                  ),
                meta: {
                  title: "Part Info",
                  roles: partInfoAllowedRoles,
                },
              },
              {
                path: "costing",
                name: RouteName.PartDetailsCosting,
                component: () =>
                  import(
                    /* webpackChunkName: 'part-details-costing' */ "@/components/part-details/PartDetailsCosting.vue"
                  ),
                meta: {
                  title: "Costing",
                  roles: partDetailsCostingAllowedRoles,
                },
              },
              {
                path: "technical-feedback",
                name: RouteName.PartDetailsTechnicalFeedback,
                component: () =>
                  import(
                    /* webpackChunkName: 'part-details-tech-feedback' */ "@/components/part-details/PartDetailsTechnicalFeedback.vue"
                  ),
                meta: {
                  title: "Part Technical Feedback",
                  roles: technicalFeedbackAllowedRoles,
                },
              },
              {
                path: "documents",
                name: RouteName.PartDetailsDocuments,
                component: () =>
                  import(
                    /* webpackChunkName: 'part-details-documents' */ "@/components/part-details/PartDetailsDocuments.vue"
                  ),
                meta: {
                  title: "Documents",
                  roles: partDetailsDocumentsAllowedRoles,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "layout" */ "@/layouts/TheMainLayout.vue"),
    children: [
      {
        path: "profile",
        name: RouteName.Profile,
        component: () =>
          import(/* webpackChunkName: 'profile' */ "@/views/profile/index.vue"),
        meta: { title: "Profile" },
      },
      {
        path: "company-account/:companyId",
        name: RouteName.CompanyAccount,
        component: () =>
          import(
            /* webpackChunkName: 'company-account' */ "@/views/CompanyAccount.vue"
          ),
        meta: { title: "Company Account", roles: [UserRoles.customer] },
      },
      {
        path: "dashboard",
        name: RouteName.Dashboard,
        component: () =>
          import(/* webpackChunkName: 'dashboard' */ "@/views/Dashboard.vue"),
        meta: {
          title: "Dashboard",
          roles: [UserRoles.customer],
        },
      },
      {
        path: "parts",
        name: RouteName.Parts,
        component: () =>
          import(/* webpackChunkName: 'parts' */ "@/views/Parts.vue"),
        meta: {
          title: "Parts",
          roles: partsAllowedAdminRoles,
        },
      },
      {
        path: "parts/:quoteId/:rfqPartId/:partId?",
        redirect: (to) => {
          return `${to.params.quoteId}/${to.params.partId}/technical-feedback${to.params.query}`;
        },
        name: RouteName.PartsPartDetails,
        component: () =>
          import(
            /* webpackChunkName: 'part-details' */ "@/views/PartDetails.vue"
          ),
        meta: {
          title: "Part Details",
          roles: partsAllowedAdminRoles,
        },
        children: [
          {
            path: "part-info",
            name: RouteName.PartsPartDetailsPartInfo,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-part-info' */ "@/components/part-details/PartDetailsPartInfo.vue"
              ),
            meta: {
              title: "Part Info",
              roles: partsAllowedAdminRoles,
            },
          },
          {
            path: "costing",
            name: RouteName.PartsPartDetailsCosting,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-costing' */ "@/components/part-details/PartDetailsCosting.vue"
              ),
            meta: {
              title: "Costing",
              roles: partsAllowedAdminRoles,
            },
          },
          {
            path: "technical-feedback",
            name: RouteName.PartsPartDetailsTechnicalFeedback,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-tech-feedback' */ "@/components/part-details/PartDetailsTechnicalFeedback.vue"
              ),
            meta: {
              title: "Part Technical Feedback",
              roles: partsAllowedAdminRoles,
            },
          },
          {
            path: "documents",
            name: RouteName.PartsPartDetailsDocuments,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-documents' */ "@/components/part-details/PartDetailsDocuments.vue"
              ),
            meta: {
              title: "Documents",
              roles: partsAllowedAdminRoles,
            },
          },
        ],
      },
      {
        path: "parts/:partId/:costingId?",
        redirect: (to) => {
          return `${to.params.id}/${to.params.partId}/order-part-info`;
        },
        name: RouteName.PartsOrderPartDetails,
        component: () =>
          import(
            /* webpackChunkName: 'part-details' */ "@/views/PartDetails.vue"
          ),
        children: [
          {
            path: "order-part-info",
            name: RouteName.PartsOrderPartDetailsPartInfo,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-part-info' */ "@/components/part-details/PartDetailsPartInfo.vue"
              ),
            meta: {
              title: "Part Info",
              roles: partsAllowedAdminRoles,
            },
          },
          {
            path: "order-part-technical-feedback",
            name: RouteName.PartsOrderPartDetailsTechnicalFeedback,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-technical-feedback' */ "@/components/part-details/PartDetailsTechnicalFeedback.vue"
              ),
            meta: {
              title: "Part TechnicalFeedback",
              roles: partsAllowedAdminRoles,
            },
          },
          {
            path: "order-part-documents",
            name: RouteName.PartsOrderPartDetailsDocuments,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-documents' */ "@/components/part-details/PartDetailsDocuments.vue"
              ),
            meta: {
              title: "Part Documents",
              roles: partsAllowedAdminRoles,
            },
          },
        ],
      },
      {
        path: "reports",
        name: RouteName.Reports,
        component: () =>
          import(/* webpackChunkName: 'reports' */ "@/views/Reports.vue"),
        meta: {
          title: "Reports",
          roles: [UserRoles.superAdmin, UserRoles.downloadEngineeringReports],
        },
      },
      {
        path: "upload-data",
        name: RouteName.UploadData,
        component: () =>
          import(
            /* webpackChunkName: 'upload-data' */ "@/views/UploadData.vue"
          ),
        meta: {
          title: "Upload Data",
          roles: uploadDataAllowedRoles,
        },
      },
      {
        path: "bids",
        name: RouteName.Bids,
        component: () =>
          import(/* webpackChunkName: 'bids' */ "@/views/Bids.vue"),
        meta: {
          title: "Bids",
          roles: openBidsPageAllowedRoles,
        },
      },
      {
        path: "bids/:quoteId/:rfqPartId/:partId?",
        redirect: (to) => {
          return `${to.params.quoteId}/${to.params.partId}/technical-feedback${to.params.query}`;
        },
        name: RouteName.BidsPartDetails,
        component: () =>
          import(
            /* webpackChunkName: 'part-details' */ "@/views/PartDetails.vue"
          ),
        meta: {
          title: "Part Details",
          roles: partDetailsAllowedRoles,
        },
        children: [
          {
            path: "part-info",
            name: RouteName.BidsPartDetailsPartInfo,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-part-info' */ "@/components/part-details/PartDetailsPartInfo.vue"
              ),
            meta: {
              title: "Part Info",
              roles: partInfoAllowedRoles,
            },
          },
          {
            path: "costing",
            name: RouteName.BidsPartDetailsCosting,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-costing' */ "@/components/part-details/PartDetailsCosting.vue"
              ),
            meta: {
              title: "Costing",
              roles: partDetailsCostingAllowedRoles,
            },
          },
          {
            path: "technical-feedback",
            name: RouteName.BidsPartDetailsTechnicalFeedback,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-tech-feedback' */ "@/components/part-details/PartDetailsTechnicalFeedback.vue"
              ),
            meta: {
              title: "Part Technical Feedback",
              roles: technicalFeedbackAllowedRoles,
            },
          },
          {
            path: "documents",
            name: RouteName.BidsPartDetailsDocuments,
            component: () =>
              import(
                /* webpackChunkName: 'part-details-documents' */ "@/components/part-details/PartDetailsDocuments.vue"
              ),
            meta: {
              title: "Documents",
              roles: partDetailsDocumentsAllowedRoles,
            },
          },
        ],
      },
      {
        path: "/track-orders",
        name: RouteName.TrackOrders,
        component: () =>
          import(
            /* webpackChunkName: "track-orders" */ "@/views/TrackOrders.vue"
          ),
        meta: {
          title: "Track Orders",
          roles: trackOrderAllowedRoles,
        },
        children: [
          {
            path: "customer-order/:id",
            name: RouteName.CustomerOrderDetails,
            component: () =>
              import(
                /* webpackChunkName: "customer-order-details" */ "@/views/CustomerOrderDetails.vue"
              ),
            meta: {
              title: "Order Details",
            },
          },
          {
            path: "purchase-order/:id",
            name: RouteName.PurchaseOrderDetails,
            component: () =>
              import(
                /* webpackChunkName: "purchase-order-details" */ "@/views/PurchaseOrderDetails.vue"
              ),
            meta: {
              title: "Order Details",
            },
          },
          {
            path: ":type/:id/:partId/:costingId?",
            redirect: (to) => {
              return `${to.params.id}/${to.params.partId}/order-part-info`;
            },
            name: RouteName.OrderPartDetails,
            component: () =>
              import(
                /* webpackChunkName: 'part-details' */ "@/views/PartDetails.vue"
              ),
            children: [
              {
                path: "order-part-info",
                name: RouteName.OrderPartDetailsPartInfo,
                component: () =>
                  import(
                    /* webpackChunkName: 'part-details-part-info' */ "@/components/part-details/PartDetailsPartInfo.vue"
                  ),
                meta: {
                  title: "Part Info",
                  roles: partInfoAllowedRoles,
                },
              },
              {
                path: "order-part-technical-feedback",
                name: RouteName.OrderPartDetailsTechnicalFeedback,
                component: () =>
                  import(
                    /* webpackChunkName: 'part-details-technical-feedback' */ "@/components/part-details/PartDetailsTechnicalFeedback.vue"
                  ),
                meta: {
                  title: "Part TechnicalFeedback",
                  roles: trackOrderTechnicalFeedbackAllowedRoles,
                },
              },
              {
                path: "order-part-documents",
                name: RouteName.OrderPartDetailsDocuments,
                component: () =>
                  import(
                    /* webpackChunkName: 'part-details-documents' */ "@/components/part-details/PartDetailsDocuments.vue"
                  ),
                meta: {
                  title: "Part Documents",
                  roles: partDetailsDocumentsAllowedRoles,
                },
              },
            ],
          },
        ],
      },
      {
        path: "/parameters",
        name: RouteName.Parameters,
        redirect: "/parameters/production-speed",
        component: () =>
          import(/* webpackChunkName: "parameters" */ "@/views/Parameters.vue"),
        meta: {
          title: "Parameters",
          roles: [UserRoles.superAdmin],
        },
        children: [
          {
            path: "financial",
            name: RouteName.FinancialParameters,
            component: () =>
              import(
                /* webpackChunkName: "financial-parameters" */ "@/components/parameters/FinancialParameters.vue"
              ),
            meta: {
              title: "Financial Parameters",
            },
          },
          {
            path: "production-speed",
            name: RouteName.ProductionSpeed,
            component: () =>
              import(
                /* webpackChunkName: "production-speed" */ "@/components/parameters/ProductionSpeedParameters.vue"
              ),
            meta: {
              title: "Production Speed",
            },
          },
          {
            path: "shipping-options",
            name: RouteName.ShippingParameters,
            component: () =>
              import(
                /* webpackChunkName: "shipping-options" */ "@/components/parameters/ShippingParameters.vue"
              ),
            meta: {
              title: "Shipping Options",
            },
          },
          {
            path: "authorization",
            name: RouteName.AuthorizationParameters,
            component: () =>
              import(
                /* webpackChunkName: "authorization" */ "@/components/parameters/AuthorizationParameters.vue"
              ),
            meta: {
              title: "Authorization Parameters",
            },
          },
          {
            path: "quality-control",
            name: RouteName.QualityControlParameters,
            component: () =>
              import(
                /* webpackChunkName: "quality-control" */ "@/components/parameters/QualityControlParameters.vue"
              ),
            meta: {
              title: "Quality Control Parameters",
            },
          },
          {
            path: "other",
            name: RouteName.OtherParameters,
            component: () =>
              import(
                /* webpackChunkName: "other-parameters" */ "@/components/parameters/OtherParameters.vue"
              ),
            meta: {
              title: "Other Parameters",
            },
          },
          {
            path: "similar-parts",
            name: RouteName.SimilarPartsParameters,
            component: () =>
              import(
                /* webpackChunkName: "similar-parts-parameters" */ "@/components/parameters/SimilarPartsParameters.vue"
              ),
            meta: {
              title: "Similar Parts Parameters",
            },
          },
        ],
      },
      {
        path: "/costing-parameters",
        name: RouteName.CostingParameters,
        redirect: "/costing-parameters/materials",
        component: () =>
          import(
            /* webpackChunkName: "parameters" */ "@/views/CostingParameters.vue"
          ),
        meta: {
          title: "Costing parameters",
          roles: costingParametersAllowedRoles,
        },
        children: [
          {
            path: "materials",
            name: RouteName.CostingMaterialParameters,
            component: () =>
              import(
                /* webpackChunkName: "material-parameters" */ "@/components/costing-parameters/MaterialParameters.vue"
              ),
            meta: {
              title: "Material Parameters",
            },
          },
          {
            path: "equivalent-materials",
            name: RouteName.CostingEquivalentMaterialParameters,
            component: () =>
              import(
                /* webpackChunkName: "equivalent-material-parameters" */ "@/components/costing-parameters/EquivalentMaterialParameters.vue"
              ),
            meta: {
              title: "Equivalent Material Parameters",
            },
          },
          {
            path: "surface-finishes",
            name: RouteName.CostingSurfaceFinishesParameters,
            component: () =>
              import(
                /* webpackChunkName: "surface-finish-parameters" */ "@/components/costing-parameters/SurfaceFinishParameters.vue"
              ),
            meta: {
              title: "Surface Finishes Parameters",
            },
          },
          {
            path: "surface-treatment",
            name: RouteName.CostingSurfaceTreatmentParameters,
            component: () =>
              import(
                /* webpackChunkName: "surface-treatment-parameters" */ "@/components/costing-parameters/SurfaceTreatmentParameters.vue"
              ),
            meta: {
              title: "Surface Treatment Parameters",
            },
          },
          {
            path: "buy-items",
            name: RouteName.CostingBuyItems,
            component: () =>
              import(
                /* webpackChunkName: "buy-items" */ "@/components/costing-parameters/BuyItems.vue"
              ),
            meta: {
              title: "Buy Items",
            },
          },
          {
            path: "heat-treatment",
            name: RouteName.CostingHeatTreatmentParameters,
            component: () =>
              import(
                /* webpackChunkName: "heat-treatment-parameters" */ "@/components/costing-parameters/HeatTreatmentParameters.vue"
              ),
            meta: {
              title: "Heat Treatment Parameters",
            },
          },
          {
            path: "mfg-process",
            name: RouteName.CostingMfgProcessParameters,
            component: () =>
              import(
                /* webpackChunkName: "mfg-process-parameters" */ "@/components/costing-parameters/MfgProcessParameters.vue"
              ),
            meta: {
              title: "Mfg. Process Parameters",
            },
          },
          {
            path: "quality-control",
            name: RouteName.CostingQualityControlParameters,
            component: () =>
              import(
                /* webpackChunkName: "quality-control-parameters" */ "@/components/costing-parameters/QualityControlParameters.vue"
              ),
            meta: {
              title: "Quality Control Parameters",
            },
          },
          {
            path: "administrative",
            name: RouteName.CostingAdministrativeParameters,
            component: () =>
              import(
                /* webpackChunkName: "administrative-parameters" */ "@/components/costing-parameters/AdministrativeParameters.vue"
              ),
            meta: {
              title: "Administrative Parameters",
            },
          },
        ],
      },
      {
        path: "/customers",
        name: RouteName.Customers,
        component: () =>
          import(/* webpackChunkName: "customers" */ "@/views/Customers.vue"),
        meta: {
          title: "Customers",
          roles: customersAllowedAdminRoles,
        },
      },
      {
        path: "/customers/:customerId",
        name: RouteName.CustomerDetails,
        component: () =>
          import(
            /* webpackChunkName: "customer-details" */ "@/views/Customer/CustomerDetails.vue"
          ),
        meta: {
          title: "Customers",
          roles: customersAllowedAdminRoles,
        },
      },
      {
        path: "/users",
        children: [
          {
            path: "",
            name: RouteName.Users,
            component: () =>
              import(/* webpackChunkName: "users" */ "@/views/user/Users.vue"),
            meta: {
              title: "Users",
              roles: usersAllowedRoles,
            },
          },
          {
            path: ":userId",
            name: RouteName.UserDetails,
            component: () =>
              import(
                /* webpackChunkName: "user-details" */ "@/views/user/UsersDetails.vue"
              ),
            meta: {
              title: "Users",
              roles: usersAllowedRoles,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    component: () =>
      import(
        /* webpackChunkName: "unauthorized-layout" */ "@/layouts/TheUnauthorizedLayout.vue"
      ),
    meta: { public: true },
    children: [
      {
        path: "login",
        name: RouteName.Login,
        component: () =>
          import(/* webpackChunkName: "auth" */ "@/views/Login.vue"),
        meta: {
          public: true,
          authPage: true,
          title: "Login",
        },
      },
      {
        path: "forgot-password",
        name: RouteName.ForgotPassword,
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword.vue"
          ),
        meta: {
          public: true,
          title: "Forgot Password",
        },
      },
      {
        path: "reset-password",
        name: RouteName.ResetPassword,
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */ "@/views/ResetPassword.vue"
          ),
        meta: {
          public: true,
          title: "Reset Password",
        },
      },
    ],
  },
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "secondary-layout" */ "@/layouts/TheSecondaryLayout.vue"
      ),
    children: [
      {
        path: "auth/sign-up",
        name: RouteName.SignUp,
        component: () =>
          import(/* webpackChunkName: "sign-up" */ "@/views/SignUp.vue"),
        meta: {
          public: true,
          authPage: true,
          title: "Sign-up",
        },
      },
    ],
  },
  {
    path: "/invite",
    name: RouteName.Invite,
    component: () =>
      import(/* webpackChunkName: "invite" */ "@/views/invites/Invite.vue"),
    meta: {
      title: "Invite",
      invitePage: true,
    },
  },
  {
    path: "/invite/sign-up",
    name: RouteName.InviteSignUp,
    component: () =>
      import(/* webpackChunkName: "invite" */ "@/views/invites/Invite.vue"),
    meta: {
      title: "Invite",
      public: true,
      invitePage: true,
    },
  },
  {
    path: "/",
    redirect: RouteName.PageNotFound,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized-layout" */ "@/layouts/TheUnauthorizedLayout.vue"
      ),
    children: [
      {
        path: "/invite/expired",
        name: RouteName.InviteExpired,
        component: () =>
          import(
            /* webpackChunkName: "invitation-expired" */ "@/views/invites/Expired.vue"
          ),
        meta: {
          public: true,
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: RouteName.PageNotFound,
        component: () =>
          import(
            /* webpackChunkName: "page-not-found" */ "@/views/PageNotFound.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isCustomerUserType = () => store.getters["user/isCustomerUserType"];
const isManufacturerUserType = () =>
  store.getters["user/isManufacturerUserType"];
const isQuoteAllowedRole = () => store.getters["user/isQuoteAllowedRole"];
const isTrackOrderAllowedRole = () =>
  store.getters["user/isTrackOrderAllowedRole"];
const isMfrBidAuthorizerRole = () =>
  store.getters["user/isMfrBidAuthorizerRole"];
const currentRoles = () => store.state.user.user?.roles || [];
const accessToken = () => store.getters["user/getAccessToken"];

const isNotAllowedAuthRoute = (to: RouteLocationNormalized) => {
  return !to.meta.public && !accessToken();
};
const toAuthPageWithToken = (to: RouteLocationNormalized) => {
  return to.meta.authPage && !!accessToken();
};

const isAllowedRoleRoute = (roles: string[]) => {
  return !roles || currentRoles().some((role) => roles.includes(role));
};

const isCustomerOnlyRoute = (to: RouteLocationNormalized) => {
  return to.meta.customerOnly && !isCustomerUserType();
};

let chineseNewYearMessage: MessageReactive | null = null;
const chineseNewYearRouteNames: string[] = [
  RouteName.Quotes,
  RouteName.QuoteDetails,
  RouteName.Checkout,
];

const renderUpdateNeededMessage: MessageRenderMessage = (props) => {
  const { type } = props;
  return h(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    NAlert,
    {
      closable: props.closable,
      onClose: () => {
        chineseNewYearMessage?.destroy();
        chineseNewYearMessage = null;
      },
      type,
      title: "Lead time extension",
      class: "custom-data-toasted chinese-new-year",
    },
    {
      default: () => h("div", props.content),
    }
  );
};

const handleChineseNewYearNotification = (toName: string, fromName: string) => {
  if (!isCustomerUserType()) {
    return;
  }
  if (chineseNewYearRouteNames.includes(toName) && !chineseNewYearMessage) {
    setTimeout(
      () => {
        chineseNewYearMessage = window.$message?.warning(
          "From Jan 10th till Feb 19th, all new orders placed for production in China are subject to additional 15 business days lead time extension, due to Chinese new year holiday. Contact your account manager for more info.",
          {
            render: renderUpdateNeededMessage,
            closable: true,
            duration: 0,
          }
        );
      },
      fromName ? 0 : 1000
    );
  } else if (
    !chineseNewYearRouteNames.includes(toName) &&
    chineseNewYearMessage
  ) {
    chineseNewYearMessage.destroy();
    chineseNewYearMessage = null;
  }
};

const setDocumentTitle = (to: RouteLocationNormalized) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
      ? `${nearestWithTitle.meta.title} | Machinix`
      : "";
  }
};

const setNextRouteByUser = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (toAuthPageWithToken(to) || isCustomerOnlyRoute(to)) {
    next({
      name: defaultPageName(),
    });
  } else if (isAllowedRoleRoute(to.meta.roles as string[]) && to.path !== "/") {
    next();
  } else if (isCustomerUserType()) {
    store.dispatch("user/triggerIsSidebarCollapsed", true);
    next({ name: RouteName.Dashboard });
  } else if (isQuoteAllowedRole()) {
    next({ name: RouteName.Quotes });
  } else if (isManufacturerUserType() && isMfrBidAuthorizerRole()) {
    next({ name: RouteName.Bids });
  } else if (isTrackOrderAllowedRole()) {
    next({ name: RouteName.TrackOrders });
  } else {
    next();
  }
};

router.beforeEach((to, from, next) => {
  setDocumentTitle(to);

  if (isNotAllowedAuthRoute(to)) {
    next({
      name: RouteName.Login,
      query: {
        redirect: to.fullPath.includes("invite") ? to.fullPath : undefined,
      },
    });
  } else if (
    from.name === RouteName.QuoteDetails &&
    (to.meta.invitePage || to.meta.authPage) &&
    accessToken()
  ) {
    next({ name: RouteName.Quotes });
  } else {
    setNextRouteByUser(to, next);
  }
});

export default router;
