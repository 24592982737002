export enum RouteName {
  Login = "Login",
  Profile = "Profile",
  CompanyAccount = "CompanyAccount",
  SignUp = "SignUp",
  UploadData = "UploadData",
  Reports = "Reports",
  TrackOrders = "TrackOrders",
  Bids = "Bids",
  Parameters = "Parameters",
  FinancialParameters = "FinancialParameters",
  ProductionSpeed = "ProductionSpeed",
  ShippingParameters = "ShippingParameters",
  QualityControlParameters = "QualityControlParameters",
  AuthorizationParameters = "AuthorizationParameters",
  CostingBuyItems = "CostingBuyItems",
  CostingParameters = "CostingParameters",
  CostingMaterialParameters = "CostingMaterialParameters",
  CostingEquivalentMaterialParameters = "CostingEquivalentMaterialParameters",
  CostingSurfaceFinishesParameters = "CostingSurfaceFinishesParameters",
  CostingSurfaceTreatmentParameters = "CostingSurfaceTreatmentParameters",
  CostingMfgProcessParameters = "CostingMfgProcessParameters",
  CostingAdministrativeParameters = "CostingAdministrativeParameters",
  CostingHeatTreatmentParameters = "CostingHeatTreatmentParameters",
  CostingQualityControlParameters = "CostingQualityControlParameters",
  Checkout = "Checkout",
  CheckOutWithPurchaseOrder = "CheckOutWithPurchaseOrder",
  OtherParameters = "OtherParameters",
  SimilarPartsParameters = "SimilarPartsParameters",
  Summary = "Summary",
  CustomerOrderDetails = "CustomerOrderDetails",
  PurchaseOrderDetails = "PurchaseOrderDetails",
  RequestForQuote = "RequestForQuote",
  Quotes = "Quotes",
  Parts = "Parts",
  QuoteDetails = "QuoteDetails",
  PartDetails = "PartDetails",
  OrderPartDetails = "OrderPartDetails",
  PartsOrderPartDetails = "PartsOrderPartDetails",
  PartsPartDetails = "PartsPartDetails",
  BidsPartDetails = "BidsPartDetails",
  PartDetailsPartInfo = "PartDetailsPartInfo",
  PartDetailsCosting = "PartDetailsCosting",
  PartDetailsTechnicalFeedback = "PartDetailsTechnicalFeedback",
  PartDetailsDocuments = "PartDetailsDocuments",
  OrderPartDetailsPartInfo = "OrderPartDetailsPartInfo",
  OrderPartDetailsDocuments = "OrderPartDetailsDocuments",
  OrderPartDetailsTechnicalFeedback = "OrderPartDetailsTechnicalFeedback",
  PartsPartDetailsPartInfo = "PartsPartDetailsPartInfo",
  PartsPartDetailsCosting = "PartsPartDetailsCosting",
  PartsPartDetailsDocuments = "PartsPartDetailsDocuments",
  PartsPartDetailsTechnicalFeedback = "PartsPartDetailsTechnicalFeedback",
  BidsPartDetailsPartInfo = "BidsPartDetailsPartInfo",
  BidsPartDetailsCosting = "BidsPartDetailsCosting",
  BidsPartDetailsTechnicalFeedback = "BidsPartDetailsTechnicalFeedback",
  BidsPartDetailsDocuments = "BidsPartDetailsDocuments",
  PartsOrderPartDetailsPartInfo = "PartsOrderPartDetailsPartInfo",
  PartsOrderPartDetailsDocuments = "PartsOrderPartDetailsDocuments",
  PartsOrderPartDetailsTechnicalFeedback = "PartsOrderPartDetailsTechnicalFeedback",
  Costing = "Costing",
  TechnicalFeedback = "TechnicalFeedback",
  Reject = "Reject",
  AskAuthorization = "AskAuthorization",
  ForgotPassword = "ForgotPassword",
  ResetPassword = "ResetPassword",
  PageNotFound = "PageNotFound",
  ComingSoon = "ComingSoon",
  Customers = "Customers",
  CustomerDetails = "CustomerDetails",
  Invite = "Invite",
  InviteSignUp = "InviteSignUp",
  InviteExpired = "InviteExpired",
  Users = "Users",
  UserDetails = "UserDetails",
  Dashboard = "Dashboard",
}
